import React from 'react'

export default function Titulo({titulo}) {
    return (
        <div className="wrapTitle">
            <h4>{titulo}</h4>
            <div className="line"></div>
        </div>
    )
}
