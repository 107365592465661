import React from 'react'
import Layout from "../components/Layout"
import { graphql } from 'gatsby'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Seo from './Seo'
import Banner from './Banner/Banner'
import '../assets/css/noticiasind.css'

export default function PostNews({data: {allContentfulNoticias:{nodes}}}) {
    const { titulo, desc, slug, content, fechaNoticia, stack, imagen } = nodes[0]

    const imageNoticia = getImage(imagen.gatsbyImageData)
    return (
        <Layout>
            <Seo title="Noticia" />
            <section className="p-14 notiInd">
                <article className="data-article">
                    <div >
                        <GatsbyImage  className='' image={imageNoticia} alt='imagen'/>
                    </div>
                    <div className="info">
                        <div className="tags-container">
                            {
                                stack.map((tag, index)=>{ 
                                    return (
                                            <span 
                                                key={index} 
                                                className="tagsNoticias"
                                            >
                                                {tag}
                                            </span>
                                            )   
                                        }) 
                            }
                        </div>
                        <h1 className='sectionTitle'>{titulo}</h1>
                        <p className='fechaStyle'>{fechaNoticia}</p>
                        <p className='descBodyPost'>{desc.desc}</p>
                        <h3 className='textBodyPost'>{content.content}</h3>
                    </div>
                </article>
                <article className='banner-article'>
                    <Banner title={titulo} tags={stack} slug={slug} />            
                </article>
            </section>
        </Layout>
    )
}

export const query = graphql`
        query($id:String!){
            allContentfulNoticias(filter: {id: {eq: $id}}) {
                nodes {
                    id
                    titulo
                    slug
                    content {
                        content
                    }
                    desc {
                        desc
                    }
                    fechaNoticia(formatString: "DD, MMMM, YYYY", locale: "es")
                    stack
                    imagen {
                        gatsbyImageData(width:1200,placeholder: BLURRED, formats: [AUTO,PNG,WEBP])
                    }
                }
            }
        }
    
    `
