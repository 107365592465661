import React, {useState, useEffect } from 'react'
import useNoticias from '../Hooks/useNoticias';
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Titulo from './Titulo'

export default function Recientes() {

    const listadoNoticias = useNoticias();
    const [noticias, guardarNoticias] = useState([]);
    useEffect(() => {
        guardarNoticias(listadoNoticias);
    }, [])
    return (
        <seccion className="wrapRecientes">
            <Titulo titulo='Recientes' />
            {noticias.map(noticia=>{
                const { id,titulo, slug, fechaNoticia} = noticia
                const image = getImage(noticia.imagen.gatsbyImageData)
                return (
                    <Link to={`/${slug}`} key={id} className="post">
                        <GatsbyImage
                        image={image}
                        alt={titulo}
                        className="img"
                        ></GatsbyImage>
                        <div>
                            <h5>{titulo}</h5>
                            <p>{fechaNoticia}</p>
                        </div>
                    </Link>
                )
            })}
        </seccion>
    )
}