import React from 'react'
import Recientes from './Recientes'
import BotonAllNews from './BotonAllNews'
import ShareButtons from './ShareButtons'
export default function Banner({title, tags, slug}) {
    return (
        <div>
            <BotonAllNews /> 
            <Recientes /> 
            <ShareButtons title={title} tags={tags} slug={slug} /> 
        </div>
    )
}
