import React from 'react'
import { Link } from 'gatsby'
import Titulo from './Titulo'
export default function BotonAllNews() {
    return (
        <seccion className="wrapRecientes">
            <Titulo titulo='Todas las Noticias' />
            <div className='flex justify-center'> 
                <Link 
                    to={'/blognoticias'} 
                    className=" smtBtm cursor-pointer text-sm rounded px-4 py-2 text-white 
                                uppercase font-bold mb-6"    
                >
                Ver las Noticias
                </Link>
            </div>    
        </seccion>
    )
}
