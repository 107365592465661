import React from 'react'
import Titulo from './Titulo'
import { graphql, useStaticQuery } from 'gatsby'
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
  } from 'react-share'

  const query = graphql`
    query{
        site {
            siteMetadata {
                title
                siteUrl
            }
        }
    }

`
export default function ShareButtons({title, tags, slug}) {
    const { site } = useStaticQuery(query)  
    const twitterHandle = "alejandralopeznoriega2018";
    const url = `${site.siteMetadata.siteUrl}/${slug}`
    return (
        <seccion className="wrapRecientes">
            <Titulo titulo='Comparte en' />
            <div>
                <FacebookShareButton url={url} >
                        <FacebookIcon  size={40} round={true}/>
                </FacebookShareButton>

                <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
                        <TwitterIcon  size={40} round={true} />
                </TwitterShareButton>

                <WhatsappShareButton url={url} title={title}>
                    <WhatsappIcon  size={40} round={true}/>
                </WhatsappShareButton>
            </div>

        </seccion>    
    )
}
